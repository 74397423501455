@use "./colors";

.mxc-title-m {
  color: colors.$mxc-title-color;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
}

.mxc-title-l {
  color: colors.$mxc-title-color;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
}

.mxc-body-s {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}

.mxc-body-m {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.mxc-body-l {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.mxc-accent-m {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ED9D20;
}

.mxc-label-m {
  color: colors.$mxc-label-color;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.mxc-info-m {
  font-family: 'Inter';
  color: colors.$mxc-label-color;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.mxc-breadcrumb-m {
  font-family: 'Inter';
  color: colors.$mxc-breadcrumb-color;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 900;
}
