@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Questrial';
  src: url('/assets/fonts/questrial/Questrial-Regular.ttf');
}
