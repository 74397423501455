@use 'assets/styles/_fonts';
@use 'assets/styles/_button';
@use 'assets/styles/_colors';
@use 'assets/styles/_container';
@use 'assets/styles/_typography';
@use 'assets/styles/_input';
@use 'assets/styles/theme';
@import "assets/styles/variables.scss";

:root {
  --mxc-primary: #ed9d20;
}

body {
  font-family: 'Poppins';
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 1px;
  text-align: left;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-snack-bar-container {
  padding: 0 !important;
  font-size: 16px;
  background: inherit;
  box-shadow: none !important;
}

.mdc-snackbar__surface {
  box-shadow: none !important;
  background: none !important;
}

.w-100 {
  width: 100% !important;
}

.loading-view {
  pointer-events: none;
  transition: all 200ms ease;
  filter: blur(2px);
  opacity: 1;
}

.centered-spinner {
  position: absolute;
  z-index: 5;
  top: 35%;
  bottom: 50%;
  right: 50%;
  left: 50%;
}

.form-field {
  height: 85px;

  div {
    margin-top: 3px;
    padding-left: 22px;
    font-size: 12px;
    font-weight: 500;
    color: #dc0000;
  }
}

.mxc-select {
  padding: 3px 6px;
  font-weight: 500;
  border-radius: 15px;
  font-size: 14px;
}

.mdc-dialog__surface {
  border-radius: 12px !important;
  border: 1px solid rgba(117, 116, 116, 0.5);
  box-shadow: -1px 16px 36px -25px rgba(55, 55, 62, 0.59) !important;
}

.dialog-close {
  position: absolute;
  top: 5px;
  right: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.file-over {
  transition: all 250ms ease;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 10px;
}

.file-over:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: 0;
}

.drop-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 36px;
  font-weight: 500;
  z-index: 1111;
  color: black;
  opacity: 0;
  transition: opacity 0.5s;
}

.drop-text.show {
  opacity: 1;
}

.drop-text.hide {
  opacity: 0;
}

.mxc-dialog {
  @media (max-width: $mxc-large-desktop-max-width) {
  }

  @media (max-width: $mxc-medium-desktop-max-width) {
  }

  @media (max-width: $mxc-small-desktop-max-width) {
  }

  @media (max-width: $mxc-smartphone-max-width) {
    //min-width: 100vw;
  }
}
