@import "variables.scss";

.mxc-container {
  min-height: 100vh;
  display: flex;

  &__sidebar {
    width: 285px;

    @media (max-width: $mxc-large-desktop-max-width) {
      width: 245px;
    }

    @media (max-width: $mxc-medium-desktop-max-width) {
      width: 225px;
    }
    @media (max-width: $mxc-small-desktop-max-width) {
      width: 200px;
    }

    @media (max-width: $mxc-tablet-max-width) {
      padding: 0;
    }
  }

  &__content {
    flex: 85%;
    width: 85%;
    background-image: url('/assets/images/bg.jpg');
    background-size: cover;
    @media (max-width: $mxc-large-desktop-max-width) {
      flex: 90%;
      width: 90%;
    }
  }

  .landing {
    &__title {
      flex: 60%;
      background-image: url('/assets/images/landing_bg.jpg');
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: 'Questrial', serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 37px;
    }

    &__content {
      flex: 40%;
    }
  }
}

.container-scrollbar {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c2c2c2;
  }
}
